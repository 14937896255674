export default (days: number) => {
  const dates: string[] = [
    new Date().toISOString().split("T")[0].split("-").reverse().join("-"),
  ];
  const today = new Date();

  for (let i = 0; i < days; i++) {
    const date = new Date();
    date.setDate(today.getDate() + i);
    dates.push(date.toISOString().split("T")[0].split("-").reverse().join("-")); // Format as DD-MM-YYYY
  }

  return dates;
};
